import { AssetWrapperT, DesignSystemComponent } from "@/types/design-system-types";
import { addHttps, cn } from "@/utils/helpers";
import { ComponentError } from "@/utils/system/component-error";
import Page from "@/utils/system/page";
import Image from "next/image";

const AssetWrapper = ({
  data, // All incoming Contentful data
  debug, // Debug framer

  desktopOnly = false,
  mobileOnly = false,

  type = "Image",
  url = data?.fields?.asset?.fields?.file?.url,
  mobileVariant = data?.fields?.mobileVariant,
  mobileVariantUrl = data?.fields?.mobileVariant?.fields?.file?.url,
  tint = data?.fields?.tint ?? "none",

  // Internal props
  className,
  onClick,
  ...props
}: DesignSystemComponent<AssetWrapperT>) => {
  try {
    const desktopOnlyClasses = desktopOnly && "hidden lg:block";
    const mobileOnlyClasses = mobileOnly && "block lg:hidden";

    const dontFlipClasses =
      data?.fields?.asset?.fields?.title.includes("{{{ NOFLIP }}}") && "no-flip";

    const getFormatFromUrl = (url?: string) => {
      if (!url) return null;
      return url.split(".").pop();
    };

    type =
      getFormatFromUrl(url)?.includes("mp4") || getFormatFromUrl(url)?.includes("webm")
        ? "Video"
        : type;

    if (type === "Image") {
      return (
        <div {...props} onClick={onClick} className="grid-center-parent">
          {debug}
          {url && (
            <div
              className={cn("image-wrapper", `tint-${tint}`, [
                desktopOnlyClasses,
                mobileOnlyClasses,
                dontFlipClasses,
              ])}
            >
              <picture className={cn(desktopOnlyClasses, mobileOnlyClasses)}>
                {mobileVariantUrl && (
                  <source
                    media="(max-width: 767px)"
                    className="mobile-variant"
                    srcSet={addHttps(mobileVariantUrl)}
                  />
                )}
                <source srcSet={addHttps(url)} />
                <Image
                  onClick={() => Page.editField(data, "asset")}
                  src={addHttps(url)}
                  width={data?.fields.asset?.fields.file.details.image?.width}
                  height={data?.fields.asset?.fields.file.details.image?.height}
                  sizes="100%"
                  className={cn("h-auto w-full object-cover", className)}
                  alt=""
                  unoptimized={true}
                />
              </picture>
            </div>
          )}
        </div>
      );
    }

    if (type === "Video") {
      // TODO: add support for poster?
      return (
        <video
          {...props}
          onClick={onClick}
          preload="metadata"
          className={cn(desktopOnlyClasses, mobileOnlyClasses, className)}
          autoPlay
          muted
          loop
          playsInline
        >
          <source src={addHttps(url)} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      );
    }
  } catch (error) {
    return <ComponentError error={error} data={data} />;
  }
};

export default AssetWrapper;
