import React, { ReactNode, HTMLProps } from "react";
import { cn } from "@/utils/helpers";

type Props = {
  children: ReactNode;
  className?: string;
} & HTMLProps<HTMLHeadingElement>;

const Heading1 = ({ children, className, ...props }: Props) => {
  const classes = cn("headline-xl", className);
  return (
    <h1 className={classes} {...props}>
      {children}
    </h1>
  );
};

export default Heading1;
