import React, { ReactNode, HTMLProps } from "react";
import { cn } from "@/utils/helpers";

type Props = {
  children: ReactNode;
  className?: string;
} & HTMLProps<HTMLHeadingElement>;

const Heading3 = ({ children, className, ...props }: Props) => {
  const classes = cn("headline-md", className);
  return (
    <h3 className={classes} {...props}>
      {children}
    </h3>
  );
};

export default Heading3;
