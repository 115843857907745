import Heading1 from "@/components/utility/heading1";
import Heading2 from "@/components/utility/heading2";
import Heading3 from "@/components/utility/heading3";
import Heading4 from "@/components/utility/heading4";
import Paragraph from "@/components/utility/paragraph";
import { DesignSystemComponent, TitleT } from "@/types/design-system-types";
import { addHttps, cn } from "@/utils/helpers";
import Page from "@/utils/system/page";
import Image from "next/image";
import Button from "../atoms/button";
import RichText from "../atoms/rich-text";
import { ComponentError } from "@/utils/system/component-error";
import ComponentDebugger from "@/utils/system/component-debugger";

const Title = ({
  data, // All incoming Contentful data
  debug, // Debug framer

  // Contentful controlled Props
  size = data?.fields?.size ?? "H2",
  icon = data?.fields?.icon,
  smallText = data?.fields?.smallText,
  title = data?.fields?.title,
  description = data?.fields?.description,
  bulletPointType = data?.fields?.bulletPointType,
  bulletPointType_v2 = data?.fields?.bulletPointType_v2,
  primaryButton = data?.fields?.primaryButton,
  secondaryButton = data?.fields?.secondaryButton,
  alignment = data?.fields?.alignment ?? "left",
  direction = data?.fields?.direction ?? "column",
  borderColor = data?.fields?.borderColor,

  // Controlled by parent
  theme = data?.fields?.theme,

  // Internal props
  className,
  ...props
}: DesignSystemComponent<TitleT>) => {
  try {
    let descriptionSize: "md" | "lg" | "xl" = "lg";
    switch (size) {
      case "H1":
        descriptionSize = "xl";
        break;
      case "H2":
        descriptionSize = "lg";
        break;
      default:
        descriptionSize = "md";
        break;
    }

    // ? Overrides border set by parent theme (e.g. accent-border-azure-100 will replace accent-border)
    const borderColorClass = borderColor && `accent-border-${borderColor}`;
    const directionClass =
      direction === "row" ? "n-title-direction-row" : "n-title-direction-column";

    if (direction === "row") {
      alignment = "left";
    }

    // ? Force the button types to primary and secondary
    if (primaryButton && primaryButton.fields) {
      primaryButton.fields.parentType = "primary";
    }
    if (secondaryButton && secondaryButton.fields) {
      secondaryButton.fields.parentType = "secondary";
    }

    return (
      <div
        {...props}
        className={cn(`n-title n-title-${alignment}`, [directionClass, className])}
      >
        {/* DEBUGGING INFO FOR LOCAL */}
        {debug}

        <div>
          {/* Header Title */}
          {title && (
            <div className={cn("n-title-text accent-border", borderColorClass)}>
              {/* Small text */}
              {smallText && (
                <Paragraph
                  type="caption"
                  className="n-title-smalltext"
                  onClick={() => Page.editField(data, "smallText")}
                >
                  {smallText as string}
                </Paragraph>
              )}

              {/* Header Icon */}
              {icon && icon.fields?.file?.details?.image && (
                <Image
                  src={addHttps(icon.fields.file.url)}
                  alt=""
                  width={icon.fields.file.details.image.width}
                  height={icon.fields.file.details.image.height}
                  className="n-title-icon"
                  onClick={() => Page.editField(data)}
                />
              )}

              {size === "H1" && (
                <Heading1 onClick={() => Page.editField(data, "title")}>
                  {title as string}
                </Heading1>
              )}
              {size === "H2" && (
                <Heading2 onClick={() => Page.editField(data, "title")}>
                  {title as string}
                </Heading2>
              )}
              {size === "H3" && (
                <Heading3 onClick={() => Page.editField(data, "title")}>
                  {title as string}
                </Heading3>
              )}
              {size === "H4" && (
                <Heading4 onClick={() => Page.editField(data, "title")}>
                  {title as string}
                </Heading4>
              )}
            </div>
          )}
        </div>

        <div>
          {/* Header Description */}
          {description && (
            <div className={cn("n-title-description", [`text-${descriptionSize}`])}>
              <RichText
                data={description}
                theme={theme}
                alignment={alignment === "left" ? "start" : "center"}
                bulletPointType={bulletPointType}
                bulletPointType_v2={bulletPointType_v2}
                fieldName="description"
                sys={data?.sys}
              />
            </div>
          )}

          {/* CTA Buttons */}
          {primaryButton || secondaryButton ? (
            <div className="n-title-buttons">
              {primaryButton && (
                <Button
                  data={primaryButton}
                  debug={<ComponentDebugger data={primaryButton} />}
                />
              )}
              {secondaryButton && (
                <Button
                  data={secondaryButton}
                  debug={<ComponentDebugger data={secondaryButton} />}
                />
              )}
            </div>
          ) : null}
        </div>
      </div>
    );
  } catch (error) {
    return <ComponentError error={error} data={data} />;
  }
};

export default Title;
