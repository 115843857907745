/**
 * @name Section
 * @description A generic section of data that can be used to display a collection of items.
 */

import Lines from "@/components/utility/lines";
import { DesignSystemComponent, SectionT } from "@/types/design-system-types";
import { cn, getContentfulEnvironment } from "@/utils/helpers";
import ComponentDebugger from "@/utils/system/component-debugger";
import { ComponentError } from "@/utils/system/component-error";
import ComponentResolver from "@/utils/system/component-resolver";
import Page from "@/utils/system/page";
import { useRouter } from "next/router";
import AssetWrapper from "../atoms/asset-wrapper";
import RichText from "../atoms/rich-text";
import Title from "../molecules/title";
import { useWindowSize } from "@/hooks/useWindowSize";

const Section = ({
  data, // All incoming Contentful data
  debug, // Debugger and live preview framer

  // Contentful controlled Props
  id = data?.fields.scrollSpy, // For scroll spy anchors
  title = data?.fields.title,
  verticalSpacing = data?.fields.verticalSpacing ?? "md",
  slots = data?.fields.slots,
  slot1 = slots?.length && slots[0],
  slot2 = slots?.length && slots[1],
  slot3 = slots?.length && slots[2],
  slot4 = slots?.length && slots[3],
  theme = data?.fields.theme ?? "white-100",
  border = data?.fields.border ?? true,
  backgroundImage = data?.fields.backgroundImage,
  scrollSpy = data?.fields.scrollSpy,
  disclaimersAndButtons = data?.fields.disclaimersAndButtons ?? "",

  // Internal props
  className,
  ...props
}: DesignSystemComponent<SectionT>) => {
  const { screenWidth } = useWindowSize();

  try {
    const router = useRouter();
    const { isLivePreview } = getContentfulEnvironment(router);

    const slotLength = [slot1, slot2, slot3, slot4].filter((slot) => slot).length;

    const cards = [slot1, slot2, slot3, slot4].filter(
      (slot) => slot?.sys?.contentType?.sys?.id === "nCard"
    );

    // For 4 cards - break at 1280px and for 3 cards - break at 1024px
    const breakpoint = cards.length === 4 ? 1280 : 1024;
    // Should be a slider when cards are present and screen width is less than breakpoint
    const shouldSlideCards = cards.length > 0 && screenWidth < breakpoint;

    // Reset the slots if cards are present and should slide
    if (shouldSlideCards) {
      slots = slots.filter((slot: any) => slot?.sys?.contentType?.sys?.id !== "nCard");

      slot1 = slots.length && slots[0];
      slot2 = slots.length && slots[1];
      slot3 = slots.length && slots[2];
      slot4 = slots.length && slots[3];
    }

    // ? TODO: potentially use later for breakpoint for sections with title as first slot
    const slot1IsTitle = slot1?.sys?.contentType?.sys?.id === "nTitle";
    const slot1IsTitleClass = slot1IsTitle && "n-section-grid-with-full-title";

    let themeClass = Page.getThemeClass(theme);

    // main-section-y-xs | main-section-y-sm | main-section-y-md
    const containerYSizeClass = `main-section-y-${verticalSpacing}`;

    // Force theme to transparent-dark if there is a background image
    if (backgroundImage) {
      themeClass = "theme-transparent-dark";
      theme = "transparent-dark";
    }

    let slotsUsed = 0;
    if (slot1) slotsUsed++;
    if (slot2) slotsUsed++;
    if (slot3) slotsUsed++;
    if (slot4) slotsUsed++;

    return (
      <section
        id={id}
        className={cn(
          `Module n-section relative ${themeClass}`,
          scrollSpy && "menu-section scroll-mt-72 lg:scroll-mt-48 xl:scroll-mt-40",
          className
        )}
        {...props}
      >
        {debug}

        {/* Background Image */}
        {backgroundImage && backgroundImage.fields.asset && (
          <AssetWrapper
            data={backgroundImage}
            type="Image"
            className="absolute h-full w-full object-cover lg:h-full"
          />
        )}

        {border && <Lines left />}
        <div className={cn("n-section-wrapper n-section-grid", [containerYSizeClass])}>
          {/* Page Header */}
          {title && (
            <div className={cn("xl:px-6", slots?.length && "mb-12")}>
              <Title
                data={title}
                debug={isLivePreview && <ComponentDebugger data={title} />}
                component-name="nTitle"
                component-id={title?.sys?.id}
              />
            </div>
          )}

          {/* Items */}
          {slotLength === 1 ? (
            <div className={cn("block w-full xl:px-6", slot1IsTitleClass)}>
              {slot1 ? <ComponentResolver data={slot1} theme={theme} /> : null}
            </div>
          ) : (
            <div
              className={cn(
                "grid w-full justify-center gap-6 md:gap-4 xl:gap-4 2xl:gap-6 xl:px-6",
                [`n-section-grid-cols-${slotLength}`, slot1IsTitleClass]
              )}
            >
              {slot1 ? (
                <ComponentResolver data={slot1} className={`total-slots-${slotsUsed}`} />
              ) : null}

              {slot2 ? (
                <ComponentResolver data={slot2} className={`total-slots-${slotsUsed}`} />
              ) : null}

              {slot3 ? (
                <ComponentResolver data={slot3} className={`total-slots-${slotsUsed}`} />
              ) : null}

              {slot4 ? (
                <ComponentResolver data={slot4} className={`total-slots-${slotsUsed}`} />
              ) : null}
            </div>
          )}

          {shouldSlideCards && (
            <div className="relative w-full mt-10">
              <div className="swipe-icon no-fade -mt-2" />

              {/* Cards Container */}
              <div className="flex overflow-x-auto snap-x snap-mandatory gap-4 hide-scrollbar pb-6">
                {cards.map((card, index) => (
                  <div
                    key={index}
                    className="flex-none w-[75vw] md:w-[50vw] lg:w-[35vw] snap-center"
                  >
                    <ComponentResolver
                      data={card}
                      theme={theme}
                      className="w-full h-full"
                    />
                  </div>
                ))}
              </div>
            </div>
          )}

          {/* Disclaimers and Buttons */}
          {disclaimersAndButtons && (
            <div className="relative mt-2 lg:mt-12 w-full items-end justify-end text-center xl:px-4">
              <RichText
                data={disclaimersAndButtons}
                theme={theme}
                fieldName="disclaimersAndButtons"
                alignment="center"
                debug={
                  isLivePreview && (
                    <ComponentDebugger
                      data={{
                        sys: {
                          contentType: { sys: { id: "disclaimersAndButtons" } },
                        },
                        ...disclaimersAndButtons,
                      }}
                    />
                  )
                }
                showDebug={true}
                sys={data?.sys}
              />
            </div>
          )}
        </div>
      </section>
    );
  } catch (error) {
    return <ComponentError error={error} data={data} />;
  }
};

export default Section;
