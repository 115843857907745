import React from "react";

type Props = {
  left?: boolean;
  right?: boolean;
  children?: React.ReactNode;
};

const Lines = ({ left, right, children }: Props) => {
  return (
    // The "hide-via-parent" class allows to selectively hide the lines from higher up in the tree
    <div className="lines-container hide-via-parent">
      <div className="relative h-full w-full">
        <div className="lines">
          <div className={`${left ? "left" : "hidden"}`}></div>
          <div className={`${right ? "right" : "hidden"}`}></div>
        </div>
        {children}
      </div>
    </div>
  );
};

export default Lines;
